import logo from './logo.svg';
import './App.css';
import { AppBar, Toolbar, IconButton, Typography, Button, Card, makeStyles } from '@material-ui/core';


import MenuIcon from '@material-ui/icons/Menu';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
  },
  card:{
    height: "1000px"
  },
  kofi: {
    border: '0px',
    height: '36px'
  },
  appBar: {
    zIndex: 10000,
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      {/* <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            GamepadAudio
          </Typography>
          <div style={{ flexGrow: 1 }} >          
          </div>
          <a href='https://ko-fi.com/L3L26GNN7' target='_blank'><img height='36' className={classes.kofi} src='https://cdn.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>

        </Toolbar>
      </AppBar> */}

      {/* <Card className={classes.card}>aaa</Card> */}
    </div>
  );
}

export default App;
