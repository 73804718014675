import logo from './logo.svg';
import './App.css';
import { Grid, AppBar, Toolbar, IconButton, Typography, Button, Drawer, Box, MenuItem, Card, FormControl, InputLabel, Input, FormHelperText, Select, makeStyles, Slider, ButtonBase, Paper } from '@material-ui/core';
import { useState } from 'react';
import { useEffect, useCallback } from 'react';
import { VolumeDown, VolumeUp, } from '@material-ui/icons';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

import MenuIcon from '@material-ui/icons/Menu';
const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    height: '240px',
    width: '400px',
    // position: 'relative',
    // zIndex: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
  },
  appBar: {
    zIndex: "1250 !important",
  },
  drawer: {
    zIndex: "1240 !important",
    position: "relative"
  },
  select: {
    width: '75%',
    margin: 'auto',
    padding: '20px'
  },
  toolbar: theme.mixins.toolbar,
  dragger: {
    width: "5px",
    cursor: "ew-resize",
    padding: "4px 0 0",
    borderTop: "1px solid #ddd",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: "1260 !important",
    backgroundColor: "#f4f7f9"
  }
}));

// const targets = ['button-1', 'button-2', 'button-3', 'button-4', 'button-left-shoulder-top', 'button-right-shoulder-top', 'button-left-shoulder-bottom', 'button-right-shoulder-bottom',
// 'button-select', 'button-start', 'stick-1', 'stick-2', 'button-dpad-top', 'button-dpad-bottom', 'button-dpad-left', 'button-dpad-right'];
// const targets = ['button-1', 'button-2', 'button-3', 'button-4', 'button-left-shoulder-top', 'button-right-shoulder-top', 'button-left-shoulder-bottom', 'button-right-shoulder-bottom', 'stick-1', 'stick-2'];
const targets = ['button-1', 'button-2', 'button-3', 'button-4', 'button-left-1', 'button-right-1', 'button-left-2', 'button-right-2', 'stick-left', 'stick-right'];
const types = ['Harp', 'Bass'];
const types2 = ['Synth1'];
const initialSetting = {
  'button-1': {
    type: 1,
    volume: 50
  },
  'button-2': {
    type: 1,
    volume: 0
  },
  'button-3': {
    type: 1,
    volume: 0
  },
  'button-4': {
    type: 1,
    volume: 0
  },
  'button-left-1': {
    type: 0,
    volume: 50
  },
  'button-right-1': {
    type: 0,
    volume: 50
  },
  'button-left-2': {
    type: 0,
    volume: 0
  },
  'button-right-2': {
    type: 0,
    volume: 0
  },
  'stick-left': {
    type: 0,
    volume: 50
  },
  'stick-right': {
    type: 0,
    volume: 50
  }
};

for(let k of Object.keys(initialSetting)) {
  // if(!(k == 'stick-1' || k == 'stick-2')){
  //   window.manager.changeType(k, initialSetting[k].type);
  // }
  window.manager.changeVolume(k, initialSetting[k].volume);
}

export const defaultDrawerWidth = 400;
const minDrawerWidth = 300;
const maxDrawerWidth = 500;

function App2(props) {
  const [target, setTarget] = useState(0);
  const [type, setType] = useState();
  const [volume, setVolume] = useState();
  const [setting, setSetting] = useState(initialSetting);
  const [sideBar, setSideBar] = useState({
    mobileOpen: false,
    isResizing: true,
    lastDownX: 0,
    newWidth: {}
  });
  

  const classes = useStyles();

  const handleTargetChange = (event) => {
    setTarget(event.target.value);
  }
  const handleTypeChange = (event) => {
    setSetting((prev) => {
      prev[targets[target]].type = event.target.value;
      return {...prev, [targets[target]]:{...prev[targets[target]], type: event.target.value} }
    });

    // managerにtypeの変更を通知する
    window.manager.changeType(targets[target], types[event.target.value]);
  }
  const handleVolumeChange = (event, value) => {
    // setVolume(event.target.value);
    setSetting((prev) => {
      // prev[targets[target]].type = event.target.value;
      return {...prev, [targets[target]]:{...prev[targets[target]], volume: value} }
    });

    // managerにtypeの変更を通知する
    window.manager.changeVolume(targets[target], value);
  }

  // const handleMousedown = e => {
  //   setSideBar({ ...sideBar, isResizing: true, lastDownX: e.clientX });
  //   console.log("handleMousedown");
  //   console.log(sideBar);
  // };

  // const handleMousemove = e => {
  //   console.log("handleMousemove");
    
  //   console.log(sideBar);
  //   // we don't want to do anything if we aren't resizing.
  //   if (!sideBar.isResizing) {
  //     return;
  //   }

  //   let offsetRight =
  //     document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
  //   let minWidth = 50;
  //   let maxWidth = 600;
  //   if (offsetRight > minWidth && offsetRight < maxWidth) {
  //     setSideBar({ ...sideBar, newWidth: { width: offsetRight } });
  //   }
  // };

  // const handleMouseup = e => {
  //   setSideBar({ ...sideBar, isResizing: false });
  //   console.log("handleMouseup");
    
  //   console.log(sideBar);
  // };

  // useEffect(() => {
  //   document.addEventListener('mousemove', e => handleMousemove(e));
  //   document.addEventListener('mouseup', e => handleMouseup(e));
  // }, []);

  const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);

  const handleMouseDown = e => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(e => {
    const newWidth = document.body.offsetWidth - e.clientX;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  return (
    <div className="App">

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            GamepadAudio
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
          <div style={{ flexGrow: 1 }} >          
          </div>
          <a href='https://ko-fi.com/L3L26GNN7' target='_blank'><img height='36' className={classes.kofi} src='https://cdn.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>

        </Toolbar>
      </AppBar>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}


      {/* <Card>{props.test}</Card>
      <FormControl>
        <InputLabel htmlFor="my-input">Email address</InputLabel>
        <Input id="my-input" aria-describedby="my-helper-text" />
        <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
        <Box display="none">
          <Select id="target" value="10">
            <MenuItem value={10}>left-stick</MenuItem>
            <MenuItem value={20}>right-stick</MenuItem>
          </Select>
        </Box>
      </FormControl> */}
      <Drawer className={classes.drawer} anchor="right" open={true} variant="permanent" BackdropProps={{ invisible: true }} PaperProps={{ style: { width: drawerWidth } }}  style={{ zIndex: 1250 }}>
      <div className={classes.toolbar} />
      <div onMouseDown={e => handleMouseDown(e)} className={classes.dragger} />
        <TwitterTweetEmbed
  tweetId={'1426434721806897156'}
/>
      </Drawer>
      <Drawer anchor="bottom" open={true} variant="permanent" BackdropProps={{ invisible: true }}>
          <Grid container 
          // justifyContent="center" 
  direction="column"
  alignItems="center"
  >
        <div className={classes.root}>
        <div className={classes.select}>
          <FormControl fullWidth  variant="outlined">
          <InputLabel id="target-label">Target</InputLabel>
          <Select 
            labelId="target-label"
            id="target"
            value={target}
            onChange={handleTargetChange}
          >
            {targets.map((v, i) => {
              return <MenuItem value={i}>{v}</MenuItem>
            })}
          </Select>
          </FormControl>
        </div>
        <div className={classes.select}>
          <FormControl fullWidth variant="outlined">
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            value={setting[targets[target]].type}
            onChange={handleTypeChange}
          >
            {target < 8
            ? types.map((v, i) => {
              return <MenuItem value={i}>{i == 0 ? v : v}</MenuItem>
            })
            : types2.map((v, i) => {
              return <MenuItem value={i}>{i == 0 ? v : v}</MenuItem>
            })}
          </Select>
          </FormControl>
        </div>
        {/* 音量 */}
        <div>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <VolumeDown />
            </Grid>
            <Grid item xs>
              <Slider value={setting[targets[target]].volume} onChange={handleVolumeChange} aria-labelledby="continuous-slider" />
            </Grid>
            <Grid item>
              <VolumeUp />
            </Grid>
          </Grid>
        </div></div>
          </Grid>
      </Drawer>
    </div>
  );
}

export default App2;
